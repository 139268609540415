import React from "react";
import { IconSquareRoundedArrowRightFilled } from "@tabler/icons-react";

import * as styles from "./FigmaToCode.module.scss";

const FigmaToCode = () => {
  return (
    <section className="bx--grid bx--col-lg-12">
      <div className={styles.container}>
        <article>
          <div className={styles.inlineContainer}>
            <span className={styles.newFeatures}><span className={styles.dot}></span>New features</span>
          </div>
          <h1>Generating Your Design into Code</h1>
          <p>Turn your Figma designs into production-ready code in minutes with Legion Code Generator. Launch faster, Work smarter.</p>
          <a
            href="https://generator.telkom.design/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.button}
          >
            Let's Try <IconSquareRoundedArrowRightFilled />
          </a>
        </article>
        <object type="image/svg+xml" data="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/figmatocode-illustration.svg" aria-label="figma to code illustration">
          Figma to Code Illustration
        </object>
      </div>
    </section>
  );
};

export default FigmaToCode;
