import React, { useState, useEffect } from "react";
import * as styles from "./CookieNotice.module.scss";

const CookieNotice = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsHidden(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };

  const handleDismiss = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsHidden(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`${styles.cookieNotice} ${isHidden ? styles.hidden : ""}`}>
      <object type="image/svg+xml" data="/images/cookie.svg" aria-label="cookie icon">
        Cookie Icon
      </object>
      <p>
        Our website use cookies. By continue navigating, we assume we have your permission to allow cookies as detailed in our <a href="/cookie-policy">Cookie Policy</a>.
      </p>
      <button onClick={handleAccept} className={styles.acceptButton}>
        Accept Cookies
      </button>
    </div>
  );
};

export default CookieNotice;
